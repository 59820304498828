import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useValueReference from '../../../../hooks/use-value-reference';
import SubscriptionContext from './subscriptionContext';

const SubscriptionProvider = ({ children }) => {
  /**
   * OPTIONS
   * Hold a collection of options that can be updated by the caller. These options supersede
   * the need for passing additional arguments to the subscription functions.
   */

  const [options, setOptions] = useState({});
  const getOptions = useValueReference(options);

  return (
    <SubscriptionContext.Provider value={{ getOptions, setOptions }}>
      {children}
    </SubscriptionContext.Provider>
  );
};

SubscriptionProvider.propTypes = {
  children: PropTypes.node,
};

export default SubscriptionProvider;
