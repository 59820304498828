import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import PaymentProvider from '../components/monetization/onboarding/payment/paymentProvider';
import SubscriptionProvider from '../components/monetization/onboarding/payment/subscriptionProvider';
import { getUserUUID } from './trackingLink';

const AppProvider = ({ children }) => {
  useEffect(() => {
    async function setWebUUID() {
      const uuid = await getUserUUID();
      window.webUUID = uuid;
    }

    setWebUUID();
  }, []);

  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          oracle {
            url
            website
          }
          stripe {
            key
            version
          }
        }
      }
    }
  `);
  const { stripe, oracle } = site.siteMetadata;

  return (
    <PaymentProvider stripe={stripe} oracle={oracle}>
      <SubscriptionProvider>{children}</SubscriptionProvider>
    </PaymentProvider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
