// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-post-template-js": () => import("./../../../src/components/blog/post/template.js" /* webpackChunkName: "component---src-components-blog-post-template-js" */),
  "component---src-components-legal-app-privacy-template-js": () => import("./../../../src/components/legal/app-privacy-template.js" /* webpackChunkName: "component---src-components-legal-app-privacy-template-js" */),
  "component---src-components-legal-template-js": () => import("./../../../src/components/legal/template.js" /* webpackChunkName: "component---src-components-legal-template-js" */),
  "component---src-components-legal-tos-template-js": () => import("./../../../src/components/legal/tos-template.js" /* webpackChunkName: "component---src-components-legal-tos-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-data-js": () => import("./../../../src/pages/data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-download-now-index-js": () => import("./../../../src/pages/download-now/index.js" /* webpackChunkName: "component---src-pages-download-now-index-js" */),
  "component---src-pages-download-now-motto-dark-js": () => import("./../../../src/pages/download-now/motto-dark.js" /* webpackChunkName: "component---src-pages-download-now-motto-dark-js" */),
  "component---src-pages-download-now-motto-js": () => import("./../../../src/pages/download-now/motto.js" /* webpackChunkName: "component---src-pages-download-now-motto-js" */),
  "component---src-pages-download-now-simple-dark-js": () => import("./../../../src/pages/download-now/simple-dark.js" /* webpackChunkName: "component---src-pages-download-now-simple-dark-js" */),
  "component---src-pages-download-now-simple-dark-reverted-js": () => import("./../../../src/pages/download-now/simple-dark-reverted.js" /* webpackChunkName: "component---src-pages-download-now-simple-dark-reverted-js" */),
  "component---src-pages-download-now-simple-js": () => import("./../../../src/pages/download-now/simple.js" /* webpackChunkName: "component---src-pages-download-now-simple-js" */),
  "component---src-pages-download-now-simple-reverted-js": () => import("./../../../src/pages/download-now/simple-reverted.js" /* webpackChunkName: "component---src-pages-download-now-simple-reverted-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manage-subscription-js": () => import("./../../../src/pages/manage-subscription.js" /* webpackChunkName: "component---src-pages-manage-subscription-js" */),
  "component---src-pages-showcase-js": () => import("./../../../src/pages/showcase.js" /* webpackChunkName: "component---src-pages-showcase-js" */),
  "component---src-pages-snapchat-sticker-js": () => import("./../../../src/pages/snapchat-sticker.js" /* webpackChunkName: "component---src-pages-snapchat-sticker-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-template-js": () => import("./../../../src/pages/template.js" /* webpackChunkName: "component---src-pages-template-js" */)
}

