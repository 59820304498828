/*************/
/* CONSTANTS */
/*************/

const host = 'https://x.bndspn.com';
const basePath = '/v1/or-zjoki0';

// eslint-disable-next-line prettier/prettier
const playStoreURL = 'https://play.google.com/store/apps/details?id=com.splice.video.editor';
// eslint-disable-next-line prettier/prettier
const appStoreURL = 'https://apps.apple.com/us/app/splice-video-editor-maker/id409838725';

/******************/
/* PUBLIC METHODS */
/******************/

function getAnonymousTrackingLink(campaign, store) {
  // Build the base URL
  const adjustLink = new URL(host);
  adjustLink.pathname = basePath;
  adjustLink.searchParams.set('t_cid', campaign);

  // Different stores have different parameters
  if (store == 'google') {
    adjustLink.searchParams.set('rdr', playStoreURL);
  } else if (store == 'apple') {
    adjustLink.searchParams.set('rdr', appStoreURL);
  } else {
    // eslint-disable-next-line no-console
    console.warn('A store is required. Valid options are: "google", "apple".');
  }

  return adjustLink.toString();
}

async function getTrackingLink(campaign, store) {
  const uuid = await getUserUUID();

  // Append the uuid at the end of the search parameters
  const newAdjustLink = new URL(getAnonymousTrackingLink(campaign, store));
  newAdjustLink.searchParams.set('adjust_web_id', uuid);
  return newAdjustLink.toString();
}

let userUUID = null;
let userUUIDPromise = null;

async function getUserUUID() {
  if (userUUID) return userUUID;

  if (!userUUIDPromise) {
    userUUIDPromise = new Promise((resolve) => {
      // Poll every 100ms
      const interval = setInterval(async () => {
        // Retrieve UUID
        const uuid = isSupported()
          ? await readFromIndexedDB()
          : readFromLocalStorage();
        // Stop search and resolve promise
        if (uuid) {
          clearInterval(interval);
          resolve(uuid);
        }
      }, 100);
    });
  }

  userUUID = await userUUIDPromise;
  return userUUID;
}

export { getAnonymousTrackingLink, getTrackingLink, getUserUUID };

/***************************/
/* INTERNAL IMPLEMENTATION */
/***************************/

function isSupported() {
  // Copied from: https://github.com/adjust/web_sdk/blob/4390d1258957a9a12f0bbec0251a7977a4b56654/src/sdk/storage/indexeddb.js#L19
  const indexedDB = getIndexedDB();
  const iOS =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  return !!indexedDB && !iOS;
}

async function readFromIndexedDB() {
  const indexedDB = getIndexedDB();
  // Check for database existence
  const databases = await indexedDB.databases();
  if (!databases.some((db) => db.name == 'adjust-sdk')) return;

  // Open the DB
  const openEvent = await new Promise(
    (resolve) => (indexedDB.open('adjust-sdk').onsuccess = resolve)
  );
  const db = openEvent.target.result;

  // Check for datastore existence
  const objectStores = db.objectStoreNames;
  if (!objectStores.contains('as')) return;

  // Retrieve the uuid
  const getEvent = await new Promise(
    (resolve) =>
      (db.transaction('as').objectStore('as').getAll().onsuccess = resolve)
  );
  const firstRecord = getEvent.target.result[0];

  // Could be too early
  if (!firstRecord) return;

  // UUID is ready
  return firstRecord.u;
}

function readFromLocalStorage() {
  // Read from localStorage
  const data = JSON.parse(localStorage.getItem('adjust-sdk.as'));

  // Could be too early
  if (!data) return;

  // UUID is ready
  return data[0].u;
}

function getIndexedDB() {
  return (
    window.indexedDB ||
    window.mozIndexedDB ||
    window.webkitIndexedDB ||
    window.msIndexedDB
  );
}
