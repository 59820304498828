import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import AppProvider from './src/helpers/appProvider';
import { onOptimizeActivation } from './src/hooks/use-optimize';

import './src/styles/global.css';

gsap.registerPlugin(ScrollTrigger);

export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>;
};

export const onRouteUpdate = async () => {
  // Optimize integration
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'optimize.activate',
      eventCallback: () => {
        onOptimizeActivation();
      },
    });
  } else {
    onOptimizeActivation();
  }
};
